<template>
  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" :width="width" :height="height" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
  viewBox="0 0 8366 8366"
  xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="Capa_x0020_1">
    <metadata id="CorelCorpID_0Corel-Layer"/>
    <g id="_2181544629280">
    <path class="fil0" d="M4069 25c-324,37 -292,671 32,668 2125,-23 3508,1495 3530,3456 4,435 668,471 712,76 272,-2427 -1905,-4474 -4274,-4200z"/>
    <path class="fil1" d="M4297 8342c324,-38 292,-672 -32,-668 -2125,22 -3507,-1495 -3529,-3457 -5,-435 -669,-471 -713,-76 -271,2427 1905,4474 4274,4201z"/>
    </g>
    <g id="_2181544628000">
    <path :fill="color" d="M3255 3541c836,710 1644,581 2301,-3 922,373 1288,1829 1312,2679 -4,110 -58,175 -183,174l-4523 9c-109,-1 -185,-41 -200,-158 2,-778 332,-2217 1293,-2701z"/>
    <path :fill="color" d="M6615 4285c199,-60 391,-178 581,-324 620,358 938,1548 1037,2182 -4,64 -30,116 -123,133l-1063 -3c74,-232 -146,-1510 -432,-1988z"/>
    <path :fill="color" d="M5906 1930c135,520 40,1024 -271,1414 340,193 604,442 834,719 1478,-309 1032,-2564 -563,-2133z"/>
    <path :fill="color" d="M4422 963c773,0 1399,627 1399,1399 0,772 -626,1399 -1399,1399 -772,0 -1398,-627 -1398,-1399 0,-772 626,-1399 1398,-1399z"/>
    <path :fill="color" d="M2254 4285c-199,-60 -392,-178 -582,-324 -619,358 -937,1548 -1036,2182 4,64 30,116 122,133l1088 -3c-162,-187 143,-1510 408,-1988z"/>
    <path :fill="color" d="M2963 1930c-135,520 -41,1024 271,1414 -340,193 -605,442 -834,719 -1478,-309 -1033,-2564 563,-2133z"/>
    </g>
  </g>
  </svg>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    color: {
      type: String,
      default: 'black'
    }
  }
});
</script>

<style type="text/css" scoped>
  .fil2 {fill:black}
  .fil1 {fill:#00BCD0}
  .fil0 {fill:#00CEB7}
</style>
