<template>
  <svg
   xml:space="preserve"
   width="78.5881mm"
   height="78.5881mm"
   version="1.1"
   style="clip-rule:evenodd;fill-rule:evenodd;image-rendering:optimizeQuality;shape-rendering:geometricPrecision;text-rendering:geometricPrecision"
   viewBox="0 0 276.47063 276.47063"
   id="svg2218"
   sodipodi:docname="incident_medico.svg"
   inkscape:version="1.1 (c68e22c387, 2021-05-23)"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg"><sodipodi:namedview
   id="namedview2220"
   pagecolor="#ffffff"
   bordercolor="#666666"
   borderopacity="1.0"
   inkscape:pageshadow="2"
   inkscape:pageopacity="0.0"
   inkscape:pagecheckerboard="0"
   inkscape:document-units="mm"
   showgrid="false"
   units="px"
   inkscape:zoom="4.368363"
   inkscape:cx="27.584704"
   inkscape:cy="49.789818"
   inkscape:window-width="1920"
   inkscape:window-height="1017"
   inkscape:window-x="1912"
   inkscape:window-y="-8"
   inkscape:window-maximized="1"
   inkscape:current-layer="Capa_x0020_1" />
 <g
   id="Capa_x0020_1"
   inkscape:label="Capa 1"
   inkscape:groupmode="layer">
  <metadata
   id="CorelCorpID_0Corel-Layer" />
  <g
   id="_1548989292096"
   transform="matrix(0.24117409,0,0,0.24117409,40.318634,0)">
   <circle
   class="fil0"
   cx="406"
   cy="406"
   r="406"
   id="circle2212" />
   <path
   class="fil1"
   d="m 176,232 h 127 v -45 c 0,-28 23,-50 51,-50 h 106 c 28,0 51,22 51,50 v 45 h 126 c 22,0 39,18 39,40 v 333 c 0,22 -17,40 -39,40 H 176 c -22,0 -40,-18 -40,-40 V 272 c 0,-22 18,-40 40,-40 z m 187,41 h 90 v 119 h 119 v 94 H 453 V 600 H 363 V 486 H 249 v -94 h 114 z m -12,-41 h 116 c -4,-10 -2,-18 -2,-30 0,-5 -4,-8 -9,-8 h -94 c -5,0 -8,3 -8,8 0,12 1,20 -3,30 z"
   id="path2214" />
  </g>
 </g>
</svg>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    color: {
      type: String,
      default: 'black'
    }
  }
});
</script>

<style type="text/css" scoped>
  .fil1 {fill:#FEFEFE}
  .fil0 {fill:#ED3237}
</style>
