import Vue from 'vue';
import Vuex from 'vuex';
import Cookies from 'js-cookie';
import VuexPersistence from 'vuex-persist';
import modules from './modules';

const vuexCookie = new VuexPersistence({
  key: 'vma',
  storage: window.sessionStorage,
  restoreState: (key) => {
    if (!Cookies.getJSON(key)) {
      Cookies.set(key, '{"auth": { "JSESSIONID":"", "user":{} }}')
    }
    return Cookies.getJSON(key)
  },
  saveState: (key, state) => Cookies.set(key, state),
  modules: ['auth']
});

const vuexLocal = new VuexPersistence({
  key: 'vma',
  storage: window.localStorage,
  modules: ['map', 'alerts', 'cameras', 'sosMessages']
});

Vue.use(Vuex);
const store = new Vuex.Store({
  modules,
  plugins: [vuexCookie.plugin, vuexLocal.plugin]
});

export default store;
