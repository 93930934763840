<template>
  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="91.2491mm" height="74.4053mm" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
  viewBox="0 0 5616 4579"
  xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="Capa_x0020_1">
    <metadata id="CorelCorpID_0Corel-Layer"/>
    <g id="_2291186954752">
    <path class="fil0" d="M2807 0c1305,0 2364,849 2364,1897 0,1047 -1059,1896 -2364,1896 -1305,0 -2364,-849 -2364,-1896 0,-1048 1059,-1897 2364,-1897zm-1475 3047c1194,1153 4076,397 3550,-1421 76,1659 -1730,2368 -3550,1421z"/>
    <path class="fil0" d="M10 2113c-300,3171 6326,3512 5542,-316 275,3008 -4933,3163 -5542,316z"/>
    </g>
  </g>
  </svg>
</template>
<script>
import Vue from 'vue';

export default Vue.extend();
</script>

<style type="text/css" scoped>
  .fil0 {fill:white}
</style>
