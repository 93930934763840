<template>
  <svg
  xml:space="preserve"
  width="48"
  height="48"
  version="1.1"
  style="clip-rule:evenodd;fill-rule:evenodd;image-rendering:optimizeQuality;shape-rendering:geometricPrecision;text-rendering:geometricPrecision"
  viewBox="0 0 746.77481 746.77481"
  id="svg2782"
  sodipodi:docname="incident_servicios_publicos.svg"
  inkscape:version="1.1 (c68e22c387, 2021-05-23)"
  xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
  xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:svg="http://www.w3.org/2000/svg"><sodipodi:namedview
  id="namedview2784"
  pagecolor="#ffffff"
  bordercolor="#666666"
  borderopacity="1.0"
  inkscape:pageshadow="2"
  inkscape:pageopacity="0.0"
  inkscape:pagecheckerboard="0"
  inkscape:document-units="mm"
  showgrid="false"
  units="px"
  inkscape:zoom="2.1841757"
  inkscape:cx="-40.976557"
  inkscape:cy="114.68858"
  inkscape:window-width="1920"
  inkscape:window-height="1017"
  inkscape:window-x="3832"
  inkscape:window-y="-8"
  inkscape:window-maximized="1"
  inkscape:current-layer="Capa_x0020_1" />
<g
  id="Capa_x0020_1"
  inkscape:label="Capa 1"
  inkscape:groupmode="layer">
 <metadata
  id="CorelCorpID_0Corel-Layer" />
 <circle
  class="fil0"
  cx="373.38742"
  cy="264.48276"
  id="circle2747"
  style="stroke-width:0.240876"
  r="264.48276" />
 <g
  id="_1548995770800"
  transform="matrix(0.24087683,0,0,0.24087683,108.90468,0)">
  <path
  class="fil1"
  d="m 473,1586 h 75 c 49,-20 100,-21 153,0 h 79 c 4,-97 56,-163 99,-238 67,-107 91,-259 29,-364 -119,-201 -440,-213 -573,0 -56,114 -54,234 33,364 51,80 88,159 105,238 z"
  id="path2749" />
  <path
  class="fil1"
  d="m 499,1628 h 248 c 20,19 23,37 0,54 H 499 c -22,-18 -21,-36 0,-54 z"
  id="path2751" />
  <path
  class="fil1"
  d="m 507,1724 h 235 c 19,19 22,37 0,54 H 507 c -21,-18 -20,-36 0,-54 z"
  id="path2753" />
  <path
  class="fil1"
  d="m 525,1815 h 195 c -16,23 -35,44 -60,63 -25,3 -48,3 -72,0 -27,-19 -47,-40 -63,-63 z"
  id="path2755" />
  <rect
  class="fil1"
  x="601"
  y="602"
  width="76.8162"
  height="145.40401"
  rx="29"
  ry="33"
  id="rect2757" />
  <rect
  class="fil1"
  transform="matrix(0.549459,0.301924,-0.34291,0.624047,862.221,647.212)"
  width="123"
  height="204"
  rx="47"
  ry="47"
  id="rect2759"
  x="0"
  y="0" />
  <rect
  class="fil1"
  transform="matrix(0.554086,-0.293345,0.333167,0.629303,318.596,683.143)"
  width="123"
  height="204"
  rx="47"
  ry="47"
  id="rect2761"
  x="0"
  y="0" />
  <rect
  class="fil1"
  transform="matrix(0.349659,-0.520386,0.591028,0.397125,124.939,879.263)"
  width="123"
  height="204"
  rx="47"
  ry="47"
  id="rect2763"
  x="0"
  y="0" />
  <rect
  class="fil1"
  transform="matrix(0.00279381,-0.626942,0.731842,0.00308724,62.8131,1164.7)"
  width="123"
  height="204"
  rx="47"
  ry="47"
  id="rect2765"
  x="0"
  y="0" />
  <rect
  class="fil1"
  transform="matrix(-0.257696,-0.571538,0.649124,-0.292678,140.672,1431.1)"
  width="123"
  height="204"
  rx="47"
  ry="47"
  id="rect2767"
  x="0"
  y="0" />
  <rect
  class="fil1"
  transform="matrix(0.364023,0.510442,-0.579733,0.413438,1076.39,813.231)"
  width="123"
  height="204"
  rx="47"
  ry="47"
  id="rect2769"
  x="0"
  y="0" />
  <rect
  class="fil1"
  transform="matrix(-0.00421689,0.626933,-0.712039,-0.00478933,1177.68,1088.08)"
  width="123"
  height="204"
  rx="47"
  ry="47"
  id="rect2771"
  x="0"
  y="0" />
  <rect
  class="fil1"
  transform="matrix(-0.211709,0.590121,-0.670229,-0.240448,1137.66,1354.6)"
  width="123"
  height="204"
  rx="47"
  ry="47"
  id="rect2773"
  x="0"
  y="0" />
 </g>
 <g
  id="_1548995748912"
  transform="matrix(0.24087683,0,0,0.24087683,108.90468,0)">
  <path
  class="fil1"
  d="m 1659,248 c 154,206 277,432 374,673 55,138 52,274 -33,396 -174,249 -543,246 -703,-12 -84,-135 -72,-267 -21,-397 96,-248 221,-464 383,-660 z"
  id="path2776" />
  <path
  class="fil2"
  d="m 1524,866 c 49,66 88,138 118,215 18,44 17,87 -10,126 -55,79 -173,78 -224,-4 -27,-43 -23,-85 -7,-126 31,-79 71,-148 123,-211 z"
  id="path2778" />
 </g>
</g>
</svg>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    color: {
      type: String,
      default: 'black'
    }
  }
});
</script>

<style type="text/css" scoped>
  .fil1 {fill:#FEFEFE}
  .fil2 {fill:#BDBFC1}
  .fil0 {fill:#5CC6D0}
</style>
