<template>
  <v-app class="app">
    <reports-drawer class="app--drawer" ref="drawer" />
    <v-main>
      <router-view />
    </v-main>
    <app-fab />
  </v-app>
</template>

<script>
import ReportsDrawer from '@/components/ReportsDrawer.vue';
import AppFab from '@/components/AppFab.vue';

export default {
  name: 'LayoutDefault',
  components: {
    ReportsDrawer,
    AppFab,
  },

  data() {
    return {
      showDrawer: true
    };
  },
  methods: {
    handleDrawerVisiable() {
      this.$refs.drawer.toggleDrawer();
    }
  },
};
</script>

<style lang="sass" scoped>
.page-wrapper
  min-height: calc(100vh - 112px - 48px)
</style>
