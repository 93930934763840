<template>
  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" :width="width" :height="height" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
    viewBox="0 0 9407 9407"
  xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="Capa_x0020_1">
    <metadata id="CorelCorpID_0Corel-Layer"/>
    <g id="_2181545356240">
    <g>
      <path class="fil0" d="M4575 28c-364,42 -328,755 36,751 2389,-26 3944,1681 3969,3886 5,489 751,530 801,86 305,-2729 -2142,-5031 -4806,-4723z"/>
      <path class="fil1" d="M4831 9379c364,-42 328,-755 -36,-751 -2389,26 -3943,-1681 -3968,-3886 -5,-489 -752,-530 -801,-86 -305,2729 2142,5030 4805,4723z"/>
    </g>
    <g>
      <g>
      <path :fill="color" d="M1303 4994l1110 -2299c193,-399 126,-989 -297,-1362 -485,-428 -1138,-478 -1637,1 -409,393 -499,925 -288,1360l1112 2300z"/>
      <circle class="fil3" cx="1298" cy="2182" r="672"/>
      </g>
      <polygon :fill="color" points="2048,3814 1730,4470 3253,4450 3708,2980 5892,2941 5732,2617 3444,2585 2838,3830 "/>
      <g>
      <path :fill="color" d="M6045 2927l672 -1391c117,-242 76,-599 -179,-825 -294,-259 -689,-289 -991,1 -248,238 -302,560 -175,823l673 1392z"/>
      <circle class="fil3" cx="6043" cy="1225" r="407"/>
      </g>
      <polygon :fill="color" points="6234,2961 6542,2299 7489,3492 6637,4774 7987,6491 7635,6573 6164,4819 6746,3563 "/>
      <g>
      <path :fill="color" d="M8250 6680l642 -1330c112,-231 73,-572 -171,-788 -281,-247 -659,-276 -947,1 -236,227 -289,535 -167,787l643 1330z"/>
      <circle class="fil3" cx="8248" cy="5053" r="389"/>
      </g>
      <polygon :fill="color" points="4835,8924 4149,8675 5255,7628 6606,8365 8198,6870 8311,7213 6693,8832 5390,8362 "/>
      <g>
      <path :fill="color" d="M3847 8865l857 -1774c149,-308 97,-763 -229,-1051 -374,-330 -878,-369 -1263,1 -315,303 -385,714 -223,1049l858 1775z"/>
      <circle class="fil3" cx="3844" cy="6695" r="518"/>
      </g>
      <polygon :fill="color" points="1233,5726 1406,5017 2567,6003 1981,7426 3640,8847 3311,8995 1526,7563 1852,6217 "/>
    </g>
    </g>
  </g>
  </svg>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    color: {
      type: String,
      default: 'black'
    }
  }
});
</script>

<style type="text/css" scoped>
  .fil2 {fill:black}
  .fil4 {fill:black}
  .fil1 {fill:#00BCD0}
  .fil0 {fill:#00CEB7}
  .fil3 {fill:white}
</style>
