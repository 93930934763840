import { LayoutAuth, LayoutDefault, LayoutReports } from '@/components/layouts';
import store from '@/store';

export const publicRoute = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '*',
    component: () => import('@/views/error/NotFound.vue'),
  },
  {
    path: '/login',
    redirect: '/auth/login',
  },
  {
    path: '/auth',
    component: LayoutAuth,
    meta: {
      title: 'Login',
    },
    redirect: '/auth/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        meta: {
          title: 'Login',
        },
        component: () => import('@/views/Login.vue'),
      },
      {
        path: 'logout',
        name: 'logout',
        component: {
          beforeRouteEnter() {
            store.dispatch('auth/logout', null, { root: true });
          },
        },
      },
    ],
  },

  {
    path: '/404',
    name: '404',
    meta: {
      title: 'Not Found',
    },
    component: () => import('@/views/error/NotFound.vue'),
  },

  {
    path: '/500',
    name: '500',
    meta: {
      title: 'Server Error',
    },
    component: () => import('@/views/error/Error.vue'),
  },
  {
    path: '/murphy-find',
    name: 'murphy-find',
    component: () => import('@/views/MurphyFindView.vue'),
    meta: {
      title: 'murphy',
      validate: (user) => {
        return user.attributes.murphyfind === 'true';
      },
    },
  },
];

export const protectedRoute = [
  {
    path: '/admin',
    name: 'admin',
    component: LayoutDefault,
    meta: {
      title: 'home',
      group: 'apps',
      icon: '',
    },
    children: [
      {
        path: '/home',
        name: 'home',
        meta: {
          title: 'home',
          group: 'apps',
          icon: '$home',
          admin: true,
        },
        component: () => import('@/views/Home.vue'),
      },
      {
        path: '/users',
        name: 'users',
        meta: {
          title: 'users',
          group: 'apps',
          icon: '$user',
          admin: true,
        },
        component: () => import('@/views/Users.vue'),
      },
      {
        path: '/groups',
        name: 'groups',
        meta: {
          title: 'groups',
          group: 'apps',
          icon: '$group',
          validate: (user) => {
            return user.attributes.permission_groups;
          },
        },
        component: () => import('@/views/Groups.vue'),
      },
      {
        path: '/cameras',
        name: 'cameras',
        meta: {
          title: 'cameras',
          group: 'apps',
          icon: '$camera',
          validate: (user) => {
            return user.attributes.permission_cameras;
          },
        },
        component: () => import('@/views/Cameras.vue'),
      },
      {
        path: '/bus',
        name: 'bus',
        meta: {
          title: 'bus',
          group: 'apps',
          icon: '$bus',
          validate: (user) => {
            return user.attributes.permission_bus;
          },
        },
        component: () => import('@/views/Bus.vue'),
      },
      {
        path: '/geofences',
        name: 'geofences',
        meta: {
          title: 'geofences',
          group: 'apps',
          icon: '$geofence',
          validate: (user) => {
            return user.attributes.permission_geofences;
          },
        },
        component: () => import('@/views/Geofences.vue'),
      },
      // {
      //   path: '/incidents',
      //   name: 'incidents',
      //   meta: {
      //     title: 'incidents',
      //     group: 'apps',
      //     icon: 'mdi-alert',
      //   },
      //   component: () => import('@/views/Incidents.vue'),
      // },
      {
        path: '/lpr',
        name: 'lpr',
        meta: {
          title: 'lpr',
          group: 'apps',
          icon: '$lpr',
          validate: (user) => {
            return user.attributes.permission_lpr;
          },
        },
        component: () => import('@/views/LPR.vue'),
      },
      {
        path: '/sensor',
        name: 'sensor',
        meta: {
          title: 'sensor',
          group: 'apps',
          icon: '$sensor',
          validate: (user) => {
            return user.attributes.permission_sensor;
          },
        },
        component: () => import('@/views/Sensor.vue'),
      },
      {
        path: '/devices',
        name: 'devices',
        meta: {
          title: 'devices',
          group: 'apps',
          icon: '$device',
          validate: (user) => {
            return user.attributes.permission_devices;
          },
        },
        component: () => import('@/views/Devices.vue'),
      },
      {
        path: '/fuelPrice',
        name: 'fuelPrice',
        meta: {
          title: 'fuelPrice',
          group: 'apps',
          icon: 'mdi-fuel',
        },
        component: () => import('@/views/FuelPrice.vue'),
      },
      {
        path: '/reports',
        name: 'reports',
        meta: {
          title: 'reports',
          group: 'apps',
          icon: 'mdi-file-chart',
        },
        redirect: '/reports/devices',
      },
      // {
      //   path: '/drivers',
      //   name: 'drivers',
      //   meta: {
      //     title: 'drivers',
      //     group: 'apps',
      //     icon: '$driver',
      //   },
      //   component: () => import('@/views/Drivers.vue'),
      // },
      // {
      //   path: '/maintenance',
      //   name: 'maintenance',
      //   meta: {
      //     title: 'maintenance',
      //     group: 'apps',
      //     icon: 'mdi-hammer-wrench',
      //   },
      //   component: () => import('@/views/Maintenance.vue'),
      // },
    ],
  },
  {
    path: '/reports',
    component: LayoutReports,
    meta: {
      title: 'home',
      group: 'apps',
      icon: '',
    },
    redirect: '/home',
    children: [
      {
        path: '/reports/cameras',
        name: 'cameras-reports',
        meta: {
          title: 'cameras',
          group: 'apps',
          icon: '$camera-white',
        },
        component: () => import('@/views/reports/CamerasReports.vue'),
      },
      // {
      //   path: '/reports/bus',
      //   name: 'bus-reports',
      //   meta: {
      //     title: 'bus',
      //     group: 'apps',
      //     icon: '$bus-white',
      //   },
      //   component: () => import('@/views/reports/BusesReports.vue'),
      // },
      // {
      //   path: '/reports/lpr',
      //   name: 'lpr-reports',
      //   meta: {
      //     title: 'lpr',
      //     group: 'apps',
      //     icon: '$lpr-white',
      //   },
      //   component: () => import('@/views/reports/LprsReports.vue'),
      // },
      // {
      //   path: '/reports/sensor',
      //   name: 'sensor-reports',
      //   meta: {
      //     title: 'sensor',
      //     group: 'apps',
      //     icon: '$sensor-white',
      //   },
      //   component: () => import('@/views/reports/SensorsReports.vue'),
      // },
      {
        path: '/reports/devices',
        name: 'devices-reports',
        meta: {
          title: 'devices',
          group: 'apps',
          icon: '$device-white',
        },
        component: () => import('@/views/reports/DevicesReports.vue'),
      },
      {
        path: '/reports/incidents',
        name: 'incidents-reports',
        meta: {
          title: 'incidents',
          group: 'apps',
          icon: 'mdi-alert-outline',
        },
        component: () => import('@/views/reports/IncidentsReports.vue'),
      },
    ],
  },
  {
    path: '/map',
    name: 'map',
    component: () => import('@/views/Map.vue'),
    meta: {
      title: 'map',
      group: 'map',
      icon: 'mdi-maps',
    },
  },
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/stream',
    name: 'stream',
    component: () => import('@/views/Stream.vue'),
  }
];
