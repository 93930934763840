<template>
  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="95.2598mm" height="68.9846mm" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
  viewBox="0 0 84260 61019"
  xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="Capa_x0020_1">
    <metadata id="CorelCorpID_0Corel-Layer"/>
    <path class="fil0" d="M0 23824c-6,-1685 402,-3168 2333,-3910l43275 -18503 5645 -1411 23629 62c3069,797 4326,2862 3214,6586l-4343 4798c-1271,1308 -2151,2952 -2273,5253 -237,2027 -1149,3513 -2431,4704l-19579 18815c-1075,1035 -2081,1055 -2999,-157l-5331 -14308c-743,-2486 -2679,-3877 -6037,-3959l-30105 0c-2442,-69 -3916,792 -4998,2030z"/>
    <path class="fil0" d="M10897 22809l-8203 116c-1365,486 -2035,1320 -2146,2432l5488 13798c685,771 1588,1058 2744,784l3214 -1881 -1411 -13171c-153,-787 -108,-1501 314,-2078z"/>
    <path class="fil0" d="M11610 25190c-62,-1266 378,-2125 1701,-2267l23387 -45c1898,480 3029,1595 3411,3331l5253 14268c456,1176 694,2352 784,3528 194,1581 -430,2469 -1960,2587l-27224 -392c-1687,-492 -2833,-1403 -3371,-2783l-1981 -18227zm5987 3446c57,-1334 557,-2338 2235,-2470l15170 0c1546,142 2361,902 2548,2195l1607 11015c191,1745 -557,2608 -2038,2784l-14778 -392c-1843,46 -2953,-845 -3489,-2470l-1255 -10662z"/>
    <path class="fil0" d="M46936 41883c267,1398 275,2796 98,4194l28067 -25401c2778,-2037 3100,-5595 1489,-10349l-2901 2744c-1777,2208 -300,5867 -3214,8389l-20227 19442c-934,860 -2012,1271 -3312,981z"/>
    <polygon class="fil0" points="47104,47330 47275,50935 53919,44252 53959,40920 "/>
    <path class="fil0" d="M47333 51660l7174 -7095 21952 -196c261,-6146 2499,-9853 7673,-9936l128 26576c-4084,185 -7050,-2386 -7997,-9937l-22168 -58c-1,1115 -488,1883 -1587,2214 -2743,406 -4731,168 -5175,-1568z"/>
  </g>
  </svg>
</template>
<script>
import Vue from 'vue';

export default Vue.extend();
</script>

<style type="text/css" scoped>
  .fil0 {fill:white}
</style>
