<template>
  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" :width="width" :height="height" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
  viewBox="0 0 6944 6944" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="Capa_x0020_1">
    <metadata id="CorelCorpID_0Corel-Layer"/>
    <g id="_2181544176816">
    <g>
      <path class="fil0" d="M3378 20c-269,31 -243,558 26,555 1764,-19 2911,1241 2930,2868 4,361 554,392 591,64 225,-2015 -1581,-3714 -3547,-3487z"/>
      <path class="fil1" d="M3567 6924c268,-31 242,-558 -27,-555 -1764,19 -2911,-1241 -2929,-2868 -4,-361 -555,-392 -592,-64 -225,2015 1581,3714 3548,3487z"/>
    </g>
    <g>
      <ellipse class="fil2" cx="3443" cy="3221" rx="3157" ry="2382"/>
      <ellipse :fill="color" cx="3453" cy="2975" rx="2667" ry="2140"/>
      <path :fill="color" d="M297 3218c-339,3578 7138,3963 6252,-356 311,3393 -5564,3569 -6252,356z"/>
      <path class="fil2" d="M1789 4273c1347,1300 4598,447 4005,-1604 85,1872 -1952,2672 -4005,1604z"/>
    </g>
    </g>
  </g>
  </svg>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    color: {
      type: String,
      default: 'black'
    }
  }
});
</script>

<style type="text/css" scoped>
  .fil3 {fill:black}
  .fil1 {fill:#00BCD0}
  .fil0 {fill:#00CEB7}
  .fil2 {fill:white}
</style>
