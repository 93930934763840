<template>
  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" :width="width" :height="height" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
    viewBox="0 0 71673 71672"
 xmlns:xlink="http://www.w3.org/1999/xlink">
 <g id="Capa_x0020_1">
  <metadata id="CorelCorpID_0Corel-Layer"/>
  <g id="_2181544171696">
   <path class="fil0" d="M34861 211c-2774,320 -2499,5752 275,5722 18203,-196 30047,12807 30235,29608 42,3726 5727,4039 6105,657 2325,-20795 -16320,-38329 -36615,-35987z"/>
   <path class="fil1" d="M36812 71462c2774,-321 2499,-5753 -275,-5723 -18203,196 -30047,-12807 -30235,-29608 -42,-3726 -5728,-4039 -6105,-657 -2325,20795 16320,38329 36615,35988z"/>
  </g>
  <g id="_2181544172112">
   <path :fill="color" d="M26645 34632c10183,8644 20027,7077 28037,-35 9472,4933 14322,14350 15975,24406 -42,1343 -699,2128 -2225,2123l-55103 101c-1322,-7 -2251,-499 -2427,-1921 1105,-9341 5740,-18568 15743,-24674z"/>
   <path :fill="color" d="M40868 3232c9409,0 17036,7628 17036,17037 0,9409 -7627,17037 -17036,17037 -9409,0 -17037,-7628 -17037,-17037 0,-9409 7628,-17037 17037,-17037z"/>
  </g>
 </g>
</svg>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    color: {
      type: String,
      default: 'black'
    }
  }
});
</script>

<style type="text/css" scoped>
  .fil2 {fill:black}
  .fil1 {fill:#00BCD0}
  .fil0 {fill:#00CEB7}
</style>
