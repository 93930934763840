<template>
  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" :width="width" :height="height" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
  viewBox="0 0 2103475 2103456"
 xmlns:xlink="http://www.w3.org/1999/xlink">
 <g id="Capa_x0020_1">
  <metadata id="CorelCorpID_0Corel-Layer"/>
  <g id="_2146283090688">
   <path class="fil0" d="M1023109 6178c-81409,9394 -73346,168830 8077,167953 534216,-5756 881821,375865 887347,868942 1220,109362 168083,118529 179166,19277 68243,-610290 -478957,-1124903 -1074590,-1056172z"/>
   <path class="fil1" d="M1080366 2097278c81407,-9394 73346,-168830 -8077,-167950 -534216,5753 -881821,-375868 -887347,-868945 -1220,-109362 -168083,-118529 -179166,-19277 -68243,610290 478957,1124903 1074590,1056172z"/>
  </g>
  <g id="_2146283092192">
   <rect :fill="color" x="257734" y="295230" width="1511601" height="1409891" rx="139533" ry="139533"/>
   <rect class="fil3" x="404862" y="431744" width="1217349" height="209028" rx="212554" ry="39130"/>
   <rect class="fil3" x="404862" y="708840" width="1217349" height="899039" rx="54969" ry="43524"/>
  </g>
 </g>
</svg>

</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    color: {
      type: String,
      default: 'black'
    }
  }
});
</script>

<style type="text/css" scoped>
  .fil2 {fill:black}
  .fil1 {fill:#00BCD0}
  .fil0 {fill:#00CEB7}
  .fil3 {fill:white}
</style>
