import Vue from 'vue';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import * as VueWindow from '@hscmap/vue-window';
import VueHotkey from 'v-hotkey'
import moment from 'moment'
import 'moment/locale/es'
import JsonExcel from 'vue-json-excel';
import { VueMaskDirective } from 'v-mask'
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './filters';
import './plugins/base';
import './plugins/chartist';
import './main.css';
import './assets/css/pattern.css';

Vue.directive('mask', VueMaskDirective);
Vue.component('downloadExcel', JsonExcel);

moment.locale('es')

Vue.use(VueLoading);
Vue.use(VueWindow)
Vue.use(VueHotkey)

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

// eslint-disable-next-line no-extend-native, func-names
String.prototype.toSlug = function () {
  const from = 'ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç';
  const to = 'AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc';
  const mapping = {};

  for (let i = 0, j = from.length; i < j; i++) mapping[from.charAt(i)] = to.charAt(i);
  // eslint-disable-next-line func-names
  const normalize = function (str) {
    const ret = [];
    for (let i = 0, j = str.length; i < j; i++) {
      const c = str.charAt(i);
      // eslint-disable-next-line no-prototype-builtins
      if (mapping.hasOwnProperty(str.charAt(i))) ret.push(mapping[c]);
      else ret.push(c);
    }
    return ret.join('').replace(/[^-A-Za-z0-9]+/g, '-').toLowerCase();
  }
  return normalize(this)
}

// eslint-disable-next-line no-extend-native, func-names
Array.prototype.toObject = function (key) {
  const object = {}
  this.forEach((item) => {
    object[item[key]] = item
  })
  return object
}
