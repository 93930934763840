<template>
  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" :width="width" :height="height" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
    viewBox="0 0 7451 7451" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="Capa_x0020_1">
    <metadata id="CorelCorpID_0Corel-Layer"/>
    <g id="_2181545751920">
    <g>
      <path class="fil0" d="M3624 22c-288,33 -260,598 29,595 1892,-21 3123,1331 3143,3078 4,387 595,420 634,68 242,-2162 -1696,-3985 -3806,-3741z"/>
      <path class="fil1" d="M3827 7429c288,-33 260,-598 -29,-595 -1892,20 -3123,-1331 -3143,-3078 -4,-387 -595,-420 -635,-68 -241,2162 1697,3984 3807,3741z"/>
    </g>
    <g>
      <path :fill="color" d="M1579 1016l0 403c-1,552 1008,578 1008,0l0 -404 1981 1 1 403c0,597 1006,576 1007,0l0 -403 457 -1c318,-1 526,325 526,451l-1 4172c0,314 -203,551 -512,551l-4945 -1c-304,0 -487,-242 -490,-536 -15,-1395 -30,-2788 -1,-4187 6,-278 254,-450 552,-450l417 1z"/>
      <path class="fil3" d="M973 2603l5195 -1 0 2835c-10,97 -74,143 -156,171l-4865 -1c-86,-31 -145,-86 -175,-170l1 -2834z"/>
      <polygon :fill="color" points="1391,3808 2176,3808 2176,4393 1391,4393 "/>
      <polygon :fill="color" points="1391,4612 2176,4612 2176,5197 1391,5197 "/>
      <polygon :fill="color" points="2587,3808 3373,3808 3373,4393 2587,4393 "/>
      <polygon :fill="color" points="2587,4612 3373,4612 3373,5197 2587,5197 "/>
      <polygon :fill="color" points="2587,3016 3373,3016 3373,3601 2587,3601 "/>
      <polygon :fill="color" points="3784,3808 4569,3808 4569,4393 3784,4393 "/>
      <polygon :fill="color" points="3784,4612 4569,4612 4569,5197 3784,5197 "/>
      <polygon :fill="color" points="3784,3016 4569,3016 4569,3601 3784,3601 "/>
      <polygon :fill="color" points="4981,3808 5766,3808 5766,4393 4981,4393 "/>
      <polygon :fill="color" points="4981,4612 5766,4612 5766,5197 4981,5197 "/>
      <polygon :fill="color" points="4981,3016 5766,3016 5766,3601 4981,3601 "/>
      <path :fill="color" d="M1787 669c-1,-318 589,-316 590,0l1 734c0,241 -589,276 -590,0l-1 -734z"/>
      <path :fill="color" d="M4770 669c-1,-318 589,-316 589,0l2 734c0,241 -589,276 -590,0l-1 -734z"/>
    </g>
    </g>
  </g>
  </svg>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    color: {
      type: String,
      default: 'black'
    }
  }
});
</script>

<style type="text/css" scoped>
  .fil2 {fill:black}
  .fil4 {fill:black}
  .fil1 {fill:#00BCD0}
  .fil0 {fill:#00CEB7}
  .fil3 {fill:white}
</style>
