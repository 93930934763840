import axios from 'axios';

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 50000,
  withCredentials: true,
});

const err = (error) => {
  const { status } = error.response;
  switch (status) {
    // case 400:
    //   window.VMA.$emit('SHOW_SNACKBAR', {
    //     show: true,
    //     text: `Bad Request ${data.message}`,
    //     color: 'red',
    //   });
    //   break;
    // case 422:
    //   window.VMA.$emit('SHOW_SNACKBAR', {
    //     show: true,
    //     text: message,
    //     color: 'red',
    //   });
    //   break;
    case 401:
      window.VMA.$emit('AUTH_FAIELD');
      break;
    case 403:
      window.VMA.$emit('ACESS_DENIED');
      break;
    case 500:
      window.VMA.$emit('SERVER_ERROR');
      break;
    default:
      break;
  }

  return Promise.reject(error);
};

// request interceptor

service.interceptors.request.use((config) => {
  // config.headers['Access-Control-Allow-Origin'] = 'https://kalan.sspsinaloa.gob.mx';
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
  return config;
}, err);

// response interceptor

service.interceptors.response.use(({ data }) => data, err);

export default service;
