<template>
  <v-app class="layout-auth ">
    <v-main>
      <router-view :key="$route.path"></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({}),

  methods: {},
};
</script>

<style lang="scss" scoped>
.layout-auth {
  background: #0b2957;
  background-image: url("/img/bg2.png"), url("/img/bg1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain, cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
</style>
