import axios from 'axios';

const ApiService = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 50000,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  paramsSerializer: params => {
    const parts = [];

    const encode = val => {
      return encodeURIComponent(val).replace(/%3A/gi, ':')
        .replace(/%24/g, '$')
        .replace(/%2C/gi, ',')
        .replace(/%20/g, '+')
        .replace(/%5B/gi, '[')
        .replace(/%5D/gi, ']');
    }

    const convertPart = (key, val) => {
      if (val instanceof Date) {
        // eslint-disable-next-line no-param-reassign
        val = val.toISOString()
      } else if (val instanceof Object) {
        // eslint-disable-next-line no-param-reassign
        val = JSON.stringify(val)
      }
      parts.push(encode(key) + '=' + encode(val));
    }

    Object.entries(params).forEach(([key, val]) => {
      if (val === null || typeof val === 'undefined') {
        return
      }
      if (Array.isArray(val)) {
        val.forEach((v, i) => convertPart(`${key}`, v))
      } else {
        convertPart(key, val)
      }
    })
    return parts.join('&')
  }
});

const err = (error) => {
  const { status } = error.response;
  switch (status) {
    case 401:
      window.VMA.$emit('AUTH_FAIELD');
      break;
    case 403:
      window.VMA.$emit('ACESS_DENIED');
      break;
    case 500:
      window.VMA.$emit('SERVER_ERROR');
      break;
    default:
      break;
  }
  return Promise.reject(error);
};

// request interceptor
ApiService.interceptors.request.use((config) => {
  return config;
}, err);

// response interceptor
ApiService.interceptors.response.use(({ data }) => data, err);

export default ApiService;
