<template>
  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" :width="width" :height="height" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
    viewBox="0 0 12842 12842"
  xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="Capa_x0020_1">
    <metadata id="CorelCorpID_0Corel-Layer"/>
    <g id="_2181545381424">
    <g>
      <path class="fil0" d="M6246 38c-497,57 -448,1030 49,1025 3262,-35 5384,2295 5418,5305 7,668 1026,724 1093,118 417,-3726 -2924,-6868 -6560,-6448z"/>
      <path class="fil1" d="M6596 12804c497,-57 447,-1031 -50,-1025 -3261,35 -5383,-2295 -5417,-5305 -7,-668 -1026,-724 -1094,-118 -416,3726 2924,6867 6561,6448z"/>
    </g>
    <g>
      <path :fill="color" d="M2721 2192l8482 0c206,0 375,170 375,376l0 5398c0,207 -169,376 -375,376l-2887 0c66,435 168,726 368,1232 116,293 -10,657 -284,657l-2948 0c-316,0 -336,-425 -202,-666 151,-271 285,-667 360,-1223l-2889 0c-207,0 -376,-169 -376,-376l0 -5398c0,-206 169,-376 376,-376z"/>
      <polygon class="fil3" points="3296,2960 10707,2960 10707,7636 3296,7636 "/>
      <path :fill="color" d="M9304 7307c136,0 246,110 246,246 0,136 -110,246 -246,246 -136,0 -246,-110 -246,-246 0,-136 110,-246 246,-246z"/>
      <path :fill="color" d="M10050 7284c135,0 245,110 245,245 0,136 -110,246 -245,246 -136,0 -246,-110 -246,-246 0,-135 110,-245 246,-245z"/>
      <g>
      <path :fill="color" d="M1327 4440l2774 0c83,0 152,68 152,152l0 5635c0,83 -69,152 -152,152l-2774 0c-84,0 -152,-69 -152,-152l0 -5635c0,-84 68,-152 152,-152z"/>
      <polygon class="fil3" points="1420,5183 4008,5183 4008,9602 1420,9602 "/>
      <circle class="fil3" cx="2730" cy="9955" r="188"/>
      <circle class="fil3" cx="3737" cy="4667" r="82"/>
      <path class="fil3" d="M2488 4756l472 0c46,0 43,108 0,108l-474 -1c-43,0 -39,-107 2,-107z"/>
      </g>
    </g>
    </g>
  </g>
  </svg>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    color: {
      type: String,
      default: 'black'
    }
  }
});
</script>

<style type="text/css" scoped>
  .fil2 {fill:black}
  .fil1 {fill:#00BCD0}
  .fil0 {fill:#00CEB7}
  .fil3 {fill:white}
</style>
