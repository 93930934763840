const defaultState = () => ({
  participant: null,
  meeting: null,
  messages: [],
});

const getters = {
  getSosMessages: (state) => state.messages,
};

const actions = {
  addMessage({ commit }, message) {
    console.log('addMessage', message)
    commit('ADD_MESSAGE', message);
  },
}

const mutations = {
  ADD_MESSAGE(state, item) {
    state.messages.push(item);
  },
}

export default {
  namespaced: true,
  state() {
    return defaultState();
  },
  getters,
  actions,
  mutations,
};
