import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import en from '@/locale/en';
import es from '@/locale/es';

import customIcons from '@/components/icons/index';

Vue.use(Vuetify, { iconfont: 'mdi' });

export default new Vuetify({
  lang: {
    locales: { es, en },
    current: 'es',
  },
  icons: {
    values: {
      ...customIcons
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#00ae87',
        secondary: '#424242',
        accent: '#4f7dd1',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        mattBlack: '#424242'
      },
      dark: {
        primary: '#00ae87',
        secondary: '#424242',
        accent: '#4f7dd1',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        mattBlack: '#424242'
      },
    },
  },
});

// 38
// 67
// 121

// 60
// 105
// 91
