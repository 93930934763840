import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';
import _ from 'underscore';
import store from '@/store';
import AuthService from '@/util/auth.service';
import * as routes from './routes';
import 'nprogress/nprogress.css';

Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  base: process.env.BASE_URL,
  routes: _.toArray(routes).reduce((acc, val) => acc.concat(val), []),
});

// router guards
router.beforeEach((to, from, next) => {
  NProgress.start();
  const token = store.getters['auth/getAccessToken'];
  if (to.name !== 'login') {
    if (token) {
      AuthService({
        url: '/session',
        method: 'get',
      }).then((data) => {
        if (data) {
          store.dispatch('auth/updateProfile', data)
        }
        const user = store.getters['auth/getUser'];
        const isAdmin = (user && user.administrator)
        const requireAdmin = to.meta.admin === true

        if (isAdmin) {
          return next()
        }
        if (requireAdmin) {
          return next({ name: 'map' })
        }
        if (!to.meta.validate) {
          return next()
        }
        if (!to.meta.validate(user)) {
          return next({ name: 'map' })
        }
        return next()
      }).catch(() => {
        store.dispatch('auth/logout');
      })
    } else {
      next({ name: 'login' });
    }
  } else if (token) {
    AuthService({
      url: '/session',
      method: 'get',
    }).then(() => {
      const user = store.getters['auth/getUser'];
      if (!to.meta.admin || (user && user.administrator)) {
        next();
      } else {
        next({ name: 'map' });
      }
    }).catch(() => {
      store.dispatch('auth/logout');
    })
  } else {
    next();
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
