<template>
  <div>
    <apexcharts
      :width="width"
      :height="height"
      :type="type"
      :options="options"
      :series="series">
    </apexcharts>
  </div>
</template>

<script>

export default {
  name: 'Chart',
  components: {
    apexcharts: () => import('vue-apexcharts')
  },
  props: {
    width: {
      type: [Number, String],
      default: '100%',
    },
    height: {
      type: [Number, String],
      default: 'auto',
    },
    type: {
      type: String,
      required: true,
      validator: v => [
        'line', 'area', 'bar', 'radar', 'histogram', 'pie', 'donut',
        'radialBar', 'scatter', 'bubble', 'heatmap', 'candlestick'
      ].includes(v),
    },
    options: Object,
    series: Array
  },
}
</script>
