<template>
  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" :width="width" :height="height" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
  viewBox="0 0 8514 8514"
  xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="Capa_x0020_1">
    <metadata id="CorelCorpID_0Corel-Layer"/>
    <g id="_2181544610144">
    <g>
      <path class="fil0" d="M4141 25c-329,38 -297,683 33,680 2162,-23 3569,1521 3591,3517 5,443 681,480 726,78 276,-2470 -1939,-4553 -4350,-4275z"/>
      <path class="fil1" d="M4373 8489c329,-38 297,-684 -33,-680 -2162,23 -3569,-1521 -3591,-3517 -5,-443 -681,-480 -726,-78 -276,2470 1939,4553 4350,4275z"/>
    </g>
    <g>
      <path :fill="color" d="M485 11l617 81 10 361 3887 10c188,23 278,116 285,266l5 806c-370,-92 -740,-93 -1110,-8l-6 -270c-1146,173 -2193,480 -3086,932l8 687 -599 109 -11 -2974z"/>
      <path :fill="color" d="M2438 6245l-10 137c-230,-128 -443,-278 -574,-529 10,-1807 564,-3126 1752,-3945 785,-448 1546,-424 2287,28 1409,1035 1719,2434 1727,3938 -113,206 -305,368 -570,492l18 -130c184,-84 338,-209 452,-388 71,-187 -118,-385 -382,-565 -1452,-571 -3565,-532 -4836,35 -251,126 -402,295 -323,566 108,169 266,283 459,361z"/>
      <path :fill="color" d="M2375 6073c-37,-234 -34,-416 69,-466 654,-554 3858,-573 4640,21 56,143 46,294 7,449 128,-35 239,-112 328,-242 30,-141 -49,-269 -221,-385 -1302,-671 -4038,-625 -5056,105 -173,224 -56,388 233,518z"/>
      <path :fill="color" d="M2484 5714c262,3232 4602,2834 4491,-32 -979,-499 -3339,-596 -4491,32z"/>
      <circle class="fil3" cx="4742" cy="7082" r="513"/>
    </g>
    </g>
  </g>
  </svg>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    color: {
      type: String,
      default: 'black'
    }
  }
});
</script>

<style type="text/css" scoped>
  .fil2 {fill:black}
  .fil1 {fill:#00BCD0}
  .fil0 {fill:#00CEB7}
  .fil3 {fill:white}
</style>
