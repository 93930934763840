<template>
  <v-app-bar color="secondary" dark app dense>
    <v-app-bar-nav-icon @click="handleDrawerToggle" />
    <v-spacer />
    <v-toolbar-items>
      <v-tooltip bottom>
        <v-btn icon @click="handleFullScreen()">
          <v-icon>mdi-fullscreen</v-icon>
        </v-btn>
        <span>PANTALLA COMPLETA</span>
      </v-tooltip>
      <router-link :to="{name: 'map'}">
        <v-btn icon >
          <v-icon>mdi-map-search</v-icon>
        </v-btn>
      </router-link>
      <!-- <v-menu
        offset-y
        origin="center center"
        class="elelvation-1"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn text slot="activator" v-on="on">
            <v-icon medium>mdi-translate</v-icon>
            <span class="ml-2"> {{ localeText }} </span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group v-model="$vuetify.lang.current">
            <v-list-item
              @click="handleChangeLocale(item)"
              v-for="item in availableLanguages"
              :key="item.value"
              :value="item.value"
            >
              <v-list-item-title v-text="item.text" />
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu> -->
      <v-menu offset-y origin="center center" transition="scale-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon large text slot="activator" v-on="on">
            <v-avatar size="30px">
              <v-icon>mdi-account</v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-list class="pa-0">
          <v-list-item
            v-for="(item, index) in profileMenus"
            :to="!item.href ? { name: item.name } : null"
            :href="item.href"
            @click="item.click"
            :disabled="item.disabled"
            :target="item.target"
            rel="noopener"
            :key="index"
          >
            <v-list-item-action v-if="item.icon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>
<script>
import Util from '@/util';

export default {
  name: 'AppToolbar',
  components: {
    // NotificationList
  },
  data() {
    return {
      profileMenus: [
        // {
        //   icon: 'mdi-account',
        //   title: 'Profile',
        //   click: this.handleProfile,
        // },
        // {
        //   icon: 'mdi-cog',
        //   title: 'Settings',
        //   click: this.handleSetting,
        // },
        {
          icon: 'mdi-logout',
          title: 'Cerrar Sesión',
          click: this.handleLogut,
        }
      ]
    };
  },
  computed: {
    toolbarColor() {
      return this.$vuetify.options.extra.mainNav;
    },
    availableLanguages() {
      const { locales } = this.$vuetify.lang;
      return Object.keys(locales).map((lang) => ({
        text: locales[lang].label,
        value: lang
      }));
    },
    localeText() {
      const find = this.availableLanguages.find(
        (item) => item.value === this.$vuetify.lang.current
      );
      return find.text;
    },
  },
  methods: {
    handleDrawerToggle() {
      this.$emit('side-icon-click');
    },
    handleFullScreen() {
      Util.toggleFullScreen();
    },
    async handleLogut() {
      if (window.confirm('Are you sure to logout?')) {
        this.$store.dispatch('auth/logout');
      }
    },
    handleChangeLocale({ value }) {
      this.$vuetify.lang.current = value;
    },
    handleSetting() {},
    handleProfile() {},
    handleGoBack() {
      this.$router.go(-1);
    },
  },
  created() {},
};
</script>

<style lang="sass" scoped></style>
