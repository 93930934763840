<template>
  <div :style="{
    height: size,
    width: size
  }">
    <div
      class="clock-chart"
      :style="{
        background: backgroundColor,
        border: '3px solid ' + color,
        height: size,
        width: size
      }"
    >
      <div class="center" :style="{ color: color }">
        <div class="percent">{{percent}}%</div>
        <div class="total">{{count}} / {{total}}</div>
      </div>
      <div
        class="capa"
        v-for="c in 8"
        :key="c"
        :style="{ transform: 'rotate(' + c * 45 + 'deg)' }"
      >
        <div class="top">
          <div
            class="line1"
            :style="{
              background: color,
            }"
          ></div>
          <div
            class="line2"
            :style="{
              background: color,
            }"
          ></div>
        </div>
      </div>
      <div class="shadow"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClockChart',
  props: {
    count: [String, Number],
    percent: [String, Number],
    total: [String, Number],
    color: {
      type: String,
      default: 'rgb(112 199 158)',
    },
    backgroundColor: {
      type: String,
      default: '#283243',
    },
    size: {
      type: [String, Number],
      default: '250px'
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.clock-chart {
  position: relative;
  /* height: 250px;
  width: 250px; */
  box-sizing: border-box;
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 25%;
  margin: auto;
}

.capa {
  position: absolute;
  height: 100%;
  width: 100%;
}

.top {
  position: absolute;
  left: 50%;
  top: 0;
  margin: 0 auto;
}

.line1 {
  position: absolute;
  width: 2px;
  height: 10px;
  left: -4px;
  right: 0;
  top: -1px;
  background: #67D2C8;
  transform: rotate(-30deg);
}

.line2 {
  position: absolute;
  width: 2px;
  height: 10px;
  left: 0;
  right: -4px;
  top: -1px;
  background: #67D2C8;
  transform: rotate(30deg);
}

.shadow {
  background: #00000080;
  bottom: -25%;
  height: 20%;
  border-radius: 100%;
  left: 0;
  position: absolute;
  transform: scale(0.95);
  width: 100%;
  z-index: 1;
}

.center {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: flex-end;
}

.percent {
  bottom: 0;
  font-weight: 900;
  font-size: 4.5em;
}

.total {
  font-weight: 600;
  font-size: 1.3em;
  height: 30%;
  padding-top: 10px;
}
</style>
