<template>
  <svg
   xml:space="preserve"
   width="48"
   height="48"
   version="1.1"
   style="clip-rule:evenodd;fill-rule:evenodd;image-rendering:optimizeQuality;shape-rendering:geometricPrecision;text-rendering:geometricPrecision"
   viewBox="0 0 746.77481 746.77279"
   id="svg2359"
   sodipodi:docname="incident_proteccion_civil.svg"
   inkscape:version="1.1 (c68e22c387, 2021-05-23)"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg"><sodipodi:namedview
   id="namedview2361"
   pagecolor="#ffffff"
   bordercolor="#666666"
   borderopacity="1.0"
   inkscape:pageshadow="2"
   inkscape:pageopacity="0.0"
   inkscape:pagecheckerboard="0"
   inkscape:document-units="mm"
   showgrid="false"
   units="px"
   inkscape:zoom="2.1841699"
   inkscape:cx="-24.036592"
   inkscape:cy="91.339048"
   inkscape:window-width="1920"
   inkscape:window-height="1017"
   inkscape:window-x="3832"
   inkscape:window-y="-8"
   inkscape:window-maximized="1"
   inkscape:current-layer="Capa_x0020_1" />
 <g
   id="Capa_x0020_1"
   inkscape:label="Capa 1"
   inkscape:groupmode="layer">
  <metadata
   id="CorelCorpID_0Corel-Layer" />
  <g
   id="_1550439547296"
   transform="matrix(0.24087682,0,0,0.24087682,108.90466,-0.00101)">
   <circle
   class="fil0"
   cx="1098"
   cy="1098"
   r="1098"
   id="circle2353" />
   <polygon
   class="fil1"
   points="1098,1754 319,1754 708,1005 1098,257 1488,1005 1878,1754 "
   id="polygon2355" />
  </g>
 </g>
</svg>

</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    color: {
      type: String,
      default: 'black'
    }
  }
});
</script>

<style type="text/css" scoped>
  .fil1 {fill:#FEFEFE}
  .fil0 {fill:#F58634}
</style>
