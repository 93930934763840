<template>
  <svg  :width="width" :height="height" viewBox="0 0 34 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="uber_rides_api_icon">
              <rect id="Rectangle-path" fill="#000000" x="0.5" y="0.5" width="33" height="33" rx="2.5"></rect>
              <path d="M31,1 C32.1045695,1 33,1.8954305 33,3 L33,31 C33,32.1045695 32.1045695,33 31,33 L3,33 C1.8954305,33 1,32.1045695 1,31 L1,3 C1,1.8954305 1.8954305,1 3,1 L31,1 L31,1 Z M31,0 L3,0 C1.34314575,-4.4408921e-16 0,1.34314575 -2.22044605e-16,3 L-2.22044605e-16,31 C0,32.6568542 1.34314575,34 3,34 L31,34 C32.6568542,34 34,32.6568542 34,31 L34,3 C34,1.34314575 32.6568542,0 31,-2.22044605e-16 L31,-2.22044605e-16 L31,0 Z" id="Shape" fill="#FFFFFF"></path>
              <path d="M17,7 C11.8643467,6.99993548 7.56356533,10.8900895 7.05,16 L14,16 L14,14.5 C14,14.2238576 14.2238576,14 14.5,14 L19.5,14 C19.7761424,14 20,14.2238576 20,14.5 L20,19.5 C20,19.7761424 19.7761424,20 19.5,20 L14.5,20 C14.2238576,20 14,19.7761424 14,19.5 L14,18 L7.05,18 C7.58928848,23.3658527 12.2862527,27.3375518 17.6671192,26.9777144 C23.0479857,26.6178769 27.1742887,22.0561349 26.9943035,16.6662543 C26.8143183,11.2763738 22.3928848,7.00006775 17,7 L17,7 Z" id="Shape" fill="#FFFFFF"></path>
          </g>
      </g>
  </svg>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    color: {
      type: String,
      default: 'black'
    }
  }
});
</script>

<style type="text/css" scoped>
  .fil3 {fill:black}
  .fil1 {fill:#00BCD0}
  .fil0 {fill:#00CEB7}
  .fil2 {fill:white}
</style>
