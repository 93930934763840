<template>
  <svg
    xml:space="preserve"
    width="48"
    height="48"
    version="1.1"
    style="clip-rule:evenodd;fill-rule:evenodd;image-rendering:optimizeQuality;shape-rendering:geometricPrecision;text-rendering:geometricPrecision"
    viewBox="0 0 1017.4637 1017.4609"
    id="svg2470"
    sodipodi:docname="incident_seguridad.svg"
    inkscape:version="1.1 (c68e22c387, 2021-05-23)"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"><sodipodi:namedview
    id="namedview2472"
    pagecolor="#ffffff"
    bordercolor="#666666"
    borderopacity="1.0"
    inkscape:pageshadow="2"
    inkscape:pageopacity="0.0"
    inkscape:pagecheckerboard="0"
    inkscape:document-units="mm"
    showgrid="false"
    units="px"
    inkscape:zoom="4.3683398"
    inkscape:cx="45.211684"
    inkscape:cy="35.139208"
    inkscape:window-width="1920"
    inkscape:window-height="1017"
    inkscape:window-x="3832"
    inkscape:window-y="-8"
    inkscape:window-maximized="1"
    inkscape:current-layer="Capa_x0020_1" />
  <g
    id="Capa_x0020_1"
    inkscape:label="Capa 1"
    inkscape:groupmode="layer">
  <metadata
    id="CorelCorpID_0Corel-Layer" />
  <circle
    class="fil0"
    cx="508.73181"
    cy="360.35031"
    id="circle2456"
    style="stroke-width:0.240877"
    r="360.35172" />
  <g
    id="_1548995741200"
    transform="matrix(0.24087682,0,0,0.24087682,148.3801,-0.0014112)">
    <path
    class="fil1"
    d="m 388,1950 c 337,542 1843,676 2171,-85 24,-58 10,-86 -41,-108 l 249,-265 c 39,-55 -220,-178 -196,-341 10,-92 -46,-143 -136,-171 -321,-5 -573,-137 -787,-340 -67,-50 -143,-63 -254,-11 -189,88 -54,256 -849,345 -31,0 -45,11 -56,32 -31,11 -49,34 -55,62 -31,158 -105,287 -199,403 -22,27 -17,59 -2,81 102,133 181,209 255,255 l -97,60 c -25,21 -27,56 -3,83 z"
    id="path2458" />
    <path
    class="fil2"
    d="m 2263,1856 361,-350 -144,-177 c -16,-20 -42,-65 -45,-149 -2,-64 -33,-115 -139,-127 -248,-27 -476,-105 -681,-291 -92,-84 -197,-106 -293,2 -90,138 -307,216 -699,254 -14,1 -53,8 -51,35 -44,3 -43,16 -50,67 -45,167 -101,258 -185,355 -16,19 -16,38 -3,65 54,109 163,187 272,264 z"
    id="path2460" />
    <path
    class="fil3"
    d="m 599,2048 c 583,-332 1149,-304 1702,0 76,-53 130,-108 147,-201 1,-42 -18,-65 -80,-93 -419,-249 -1331,-274 -1738,-5 l -148,98 c -50,22 38,166 117,201 z"
    id="path2462" />
    <path
    class="fil2"
    d="m 2316,2051 c -264,321 -1223,392 -1723,1 497,-344 1176,-309 1723,-1 z"
    id="path2464" />
    <path
    class="fil1"
    d="m 1290,1047 -276,-25 c 6,65 88,236 72,275 -16,37 -147,146 -184,203 l 264,97 35,269 c 41,0 189,-103 245,-131 l 239,138 c 38,-60 39,-199 46,-280 l 265,-98 c -53,-63 -129,-148 -190,-200 l 80,-271 -290,26 c -16,-40 -119,-208 -151,-232 -49,71 -96,158 -155,229 z"
    id="path2466" />
  </g>
  </g>
  </svg>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    color: {
      type: String,
      default: 'black'
    }
  }
});
</script>

<style type="text/css" scoped>
  .fil1 {fill:#FEFEFE}
  .fil3 {fill:#BDBFC1}
  .fil0 {fill:#2B2F58}
  .fil2 {fill:#2B2F58}
</style>
