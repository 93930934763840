<template>
  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="94.3049mm" height="90.2518mm" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
  viewBox="0 0 36282 34723"
  xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="Capa_x0020_1">
    <metadata id="CorelCorpID_0Corel-Layer"/>
    <path class="fil0" d="M4996 18357l4542 -9404c789,-1632 516,-4046 -1213,-5570 -1984,-1749 -4656,-1954 -6696,6 -1672,1606 -2041,3782 -1181,5561l4548 9407zm-16 -14250c1517,0 2747,1231 2747,2748 0,1518 -1230,2748 -2747,2748 -1518,0 -2748,-1230 -2748,-2748 0,-1517 1230,-2748 2748,-2748z"/>
    <polygon class="fil0" points="8045,13529 6743,16215 12972,16133 14834,10118 23765,9962 23114,8635 13754,8503 11274,13595 "/>
    <path class="fil0" d="M24393 9904l2749 -5692c477,-988 312,-2449 -735,-3371 -1200,-1059 -2818,-1183 -4053,3 -1011,972 -1235,2289 -714,3366l2753 5694zm-11 -8625c919,0 1663,745 1663,1663 0,919 -744,1663 -1663,1663 -918,0 -1663,-744 -1663,-1663 0,-918 745,-1663 1663,-1663z"/>
    <polygon class="fil0" points="25167,10042 26426,7336 30298,12215 26813,17459 32336,24480 30896,24814 24880,17643 27259,12503 "/>
    <path class="fil0" d="M33412 25251l2626 -5438c456,-944 299,-2340 -701,-3222 -1148,-1012 -2693,-1130 -3873,3 -967,929 -1180,2188 -683,3217l2631 5440zm-10 -8241c878,0 1589,711 1589,1589 0,878 -711,1589 -1589,1589 -878,0 -1589,-711 -1589,-1589 0,-878 711,-1589 1589,-1589z"/>
    <polygon class="fil0" points="19444,34431 16638,33414 21161,29130 26689,32143 33200,26028 33659,27432 27041,34053 21713,32132 "/>
    <path class="fil0" d="M15404 34187l3503 -7254c609,-1260 398,-3122 -936,-4298 -1530,-1349 -3591,-1507 -5165,5 -1290,1239 -1574,2918 -911,4290l3509 7257zm-13 -10993c1170,0 2119,949 2119,2120 0,1171 -949,2120 -2119,2120 -1171,0 -2120,-949 -2120,-2120 0,-1171 949,-2120 2120,-2120z"/>
    <polygon class="fil0" points="4711,21351 5419,18451 10168,22482 7771,28305 14556,34115 13210,34723 5911,28862 7243,23357 "/>
  </g>
  </svg>
</template>
<script>
import Vue from 'vue';

export default Vue.extend();
</script>

<style type="text/css" scoped>
  .fil0 {fill:white}
</style>
