<template>
  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" :width="width" :height="height" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
  viewBox="0 0 10417 10417"
  xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
  </defs>
  <g id="Capa_x0020_1">
    <metadata id="CorelCorpID_0Corel-Layer"/>
    <g id="_2181544623584">
    <g>
      <path class="fil0" d="M5067 31c-403,46 -364,836 40,831 2645,-28 4367,1862 4394,4304 6,541 833,587 887,95 338,-3022 -2372,-5571 -5321,-5230z"/>
      <path class="fil1" d="M5350 10386c403,-46 364,-836 -40,-831 -2645,28 -4367,-1862 -4394,-4304 -6,-541 -833,-587 -887,-95 -338,3022 2372,5571 5321,5230z"/>
    </g>
    <g>
      <path :fill="color" d="M67 4474c-1,-199 47,-373 274,-461l5097 -2179 665 -166 2783 7c361,94 509,338 378,776l-511 565c-150,154 -254,348 -268,619 -28,238 -135,413 -286,554l-2306 2216c-127,122 -245,124 -353,-19l-628 -1685c-88,-293 -316,-457 -711,-466l-3546 0c-287,-8 -461,93 -588,239z"/>
      <path :fill="color" d="M1350 4354l-966 14c-161,57 -240,155 -253,286l647 1625c80,91 187,125 323,93l378 -222 -166 -1551c-18,-93 -13,-177 37,-245z"/>
      <path :fill="color" d="M1434 4635c-7,-149 45,-250 200,-267l2755 -6c223,57 356,188 401,393l619 1680c54,139 82,277 92,416 23,186 -50,290 -230,304l-3207 -46c-198,-58 -333,-165 -397,-328l-233 -2146z"/>
      <path class="fil3" d="M2139 5041c7,-158 66,-276 263,-291l1787 0c182,16 278,106 300,258l189 1297c23,206 -65,308 -240,328l-1740 -46c-217,6 -348,-99 -411,-291l-148 -1255z"/>
      <path :fill="color" d="M5594 6601c32,164 33,329 12,494l3305 -2992c328,-240 366,-659 176,-1219l-342 323c-209,261 -35,691 -378,988l-2383 2290c-110,101 -236,150 -390,116z"/>
      <polygon :fill="color" points="5614,7242 5634,7667 6417,6880 6421,6487 "/>
      <path :fill="color" d="M5641 7752l845 -835 2585 -24c31,-723 295,-1160 904,-1170l15 3130c-481,22 -830,-281 -942,-1170l-2610 -7c-1,131 -58,222 -187,261 -323,48 -557,20 -610,-185z"/>
    </g>
    </g>
  </g>
  </svg>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    color: {
      type: String,
      default: 'black'
    }
  }
});
</script>

<style type="text/css" scoped>
  .fil2 {fill:black}
  .fil1 {fill:#00BCD0}
  .fil0 {fill:#00CEB7}
  .fil3 {fill:white}
</style>
