var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
  height: _vm.size,
  width: _vm.size
})},[_c('div',{staticClass:"clock-chart",style:({
      background: _vm.backgroundColor,
      border: '3px solid ' + _vm.color,
      height: _vm.size,
      width: _vm.size
    })},[_c('div',{staticClass:"center",style:({ color: _vm.color })},[_c('div',{staticClass:"percent"},[_vm._v(_vm._s(_vm.percent)+"%")]),_c('div',{staticClass:"total"},[_vm._v(_vm._s(_vm.count)+" / "+_vm._s(_vm.total))])]),_vm._l((8),function(c){return _c('div',{key:c,staticClass:"capa",style:({ transform: 'rotate(' + c * 45 + 'deg)' })},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"line1",style:({
            background: _vm.color,
          })}),_c('div',{staticClass:"line2",style:({
            background: _vm.color,
          })})])])}),_c('div',{staticClass:"shadow"})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }