<template>
  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" :width="width" :height="height" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
    viewBox="0 0 9999 9999"
 xmlns:xlink="http://www.w3.org/1999/xlink">
 <g id="Capa_x0020_1">
  <metadata id="CorelCorpID_0Corel-Layer"/>
  <g id="_2181544632096">
   <path class="fil0" d="M4863 29c-387,45 -348,803 39,799 2539,-28 4192,1786 4218,4130 6,520 799,564 852,92 324,-2901 -2277,-5347 -5109,-5021z"/>
   <path class="fil1" d="M5136 9970c387,-45 348,-803 -39,-799 -2539,28 -4192,-1787 -4218,-4130 -6,-520 -799,-564 -852,-92 -324,2901 2277,5347 5109,5021z"/>
  </g>
  <g id="_2181544634560">
   <path :fill="color" d="M7277 8088l0 714c0,84 -69,152 -152,152l-514 0c-84,0 -152,-68 -153,-152l-1 -714 820 0z"/>
   <path :fill="color" d="M8150 8089l0 713c0,84 -69,152 -152,152l-514 0c-84,0 -153,-68 -153,-152l0 -713 819 0z"/>
   <path :fill="color" d="M2870 944l9 -148c4,-69 70,-136 152,-136l3927 0c78,0 152,75 152,144l1 148 -4241 -8z"/>
   <path :fill="color" d="M2012 1048l5920 -1c243,146 353,443 398,815 132,2059 94,4019 -45,5919 -6,183 -54,274 -201,272l-6208 9c-115,7 -160,-79 -196,-268 -125,-1895 -166,-3783 -43,-5656 -18,-478 111,-838 375,-1090z"/>
   <path class="fil3 str0" d="M2500 1474l-4 531c19,45 53,60 97,55l4800 0c55,-2 91,-21 89,-77l9 -492c-8,-51 -38,-81 -94,-84l-4819 -7c-42,5 -70,27 -78,74z"/>
   <path :fill="color" d="M1835 2238l6299 0c66,1210 83,2420 36,3630l-6360 8c-13,-1213 -19,-2425 25,-3638z"/>
   <path class="fil3" d="M1931 2516c8,-115 56,-174 148,-174l5801 -5c105,0 163,77 170,174 77,1112 65,2134 42,3121 -3,113 -26,164 -91,164l-5973 -11c-82,0 -139,-28 -142,-96 -40,-1057 -28,-2115 45,-3173z"/>
   <path :fill="color" d="M1688 5919l33 840c321,0 631,15 924,59 283,57 311,217 332,494l26 375c9,139 150,204 329,198l3413 -17c139,-3 232,-71 239,-263l18 -312c-3,-230 70,-404 269,-468 307,-56 637,-63 972,-57l42 -857 -6597 8z"/>
   <path :fill="color" d="M8348 2175l343 -3c40,-2 69,11 75,60l-1 106c0,34 -22,49 -61,49l-1 57 113 0c51,-4 82,31 95,99l9 893c-4,48 -33,78 -85,93l-326 0c-45,-11 -78,-43 -98,-96l9 -889c6,-48 23,-98 80,-99l116 -1 -1 -60 -255 -1 -12 -208z"/>
   <path :fill="color" d="M1634 2178l-342 -3c-40,-2 -69,11 -75,59l0 107c0,34 22,49 62,49l0 57 -112 0c-52,-4 -82,31 -95,99l-10 892c4,49 33,79 85,94l326 0c46,-11 78,-43 98,-96l-9 -889c-6,-48 -22,-99 -80,-99l-116 -1 1 -60 256 -1 11 -208z"/>
   <path class="fil4" d="M2919 7340l-1022 -10c-111,-16 -191,-79 -223,-214l-41 -353c343,-11 674,7 992,56 216,61 296,246 294,521z"/>
   <ellipse class="fil5" cx="1958" cy="7086" rx="186" ry="191"/>
   <g>
    <path :fill="color" d="M2577 5945l68 81 313 -277c73,-51 126,-83 225,-83l1523 1 0 -21 974 0 -1 -43 -973 1 0 -30 -1519 1c-113,8 -203,30 -282,99l-328 271z"/>
    <ellipse :fill="color" cx="2584" cy="6005" rx="85" ry="79"/>
   </g>
   <path :fill="color" d="M7424 5938l-66 77 -456 -370c-88,-83 -304,-169 -403,-169l-1303 -13 -2 -32 -974 -1 2 -42 972 1 0 -30 1302 3c164,9 395,136 474,204l454 372z"/>
   <ellipse class="fil6 str0" cx="7442" cy="6015" rx="85" ry="79"/>
   <path class="fil5" d="M3196 6847l38 382c5,51 40,76 93,76l3290 -3c69,0 123,-22 129,-78l37 -360c5,-52 -32,-113 -93,-112l-3397 2c-56,0 -103,41 -97,93z"/>
   <polygon :fill="color" points="3280,6838 3331,7212 6660,7216 6690,6847 "/>
   <path class="fil4" d="M7063 7340l1022 -10c111,-16 191,-79 223,-214l41 -353c-343,-11 -674,7 -992,56 -216,61 -296,246 -294,521z"/>
   <ellipse class="fil5" cx="8009" cy="7075" rx="178" ry="170"/>
   <ellipse class="fil5" cx="7522" cy="7173" rx="125" ry="119"/>
   <ellipse class="fil5" cx="2452" cy="7162" rx="114" ry="118"/>
   <ellipse class="fil6 str0" cx="7462" cy="7635" rx="125" ry="119"/>
   <ellipse class="fil6 str0" cx="2513" cy="7630" rx="114" ry="118"/>
   <path :fill="color" d="M2641 8088l0 714c0,84 -68,152 -152,152l-514 0c-84,0 -152,-68 -152,-152l-1 -714 819 0z"/>
   <path :fill="color" d="M3514 8089l0 713c0,84 -68,152 -152,152l-514 0c-84,0 -152,-68 -152,-152l0 -713 818 0z"/>
  </g>
 </g>
</svg>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    color: {
      type: String,
      default: 'black'
    }
  }
});
</script>

<style type="text/css" scoped>
  .str0 {stroke:#373435;stroke-width:7.61931}
  .fil3 {fill:#FEFEFE}
  .fil2 {fill:black}
  .fil1 {fill:#00BCD0}
  .fil0 {fill:#00CEB7}
  .fil6 {fill:#4D4D4D}
  .fil4 {fill:#B3B3B3}
  .fil5 {fill:white}
</style>
