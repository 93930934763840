<template>
  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" :width="width" :height="height" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
  viewBox="0 0 11901 11901"
  xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="Capa_x0020_1">
    <metadata id="CorelCorpID_0Corel-Layer"/>
    <g id="_2181544624864">
    <g>
      <path class="fil0" d="M5789 35c-461,53 -415,955 45,950 3023,-32 4990,2127 5021,4917 7,618 951,670 1014,109 386,-3453 -2710,-6365 -6080,-5976z"/>
      <path class="fil1" d="M6113 11866c460,-53 415,-955 -46,-950 -3023,33 -4989,-2126 -5021,-4916 -6,-619 -951,-671 -1013,-109 -386,3453 2710,6364 6080,5975z"/>
    </g>
    <g>
      <path :fill="color" d="M2106 10521c-2,-240 24,-480 87,-720 -1021,-1060 -200,-2323 1012,-2083 1546,-1711 4495,-1903 6231,0 1576,-270 1986,1456 996,2087l53 716 -8379 0z"/>
      <path class="fil3" d="M3532 7870c201,138 343,322 436,546 1334,-1440 3366,-1493 4759,1 107,-210 246,-396 431,-545 -1572,-1674 -4223,-1594 -5626,-2z"/>
      <path class="fil4" d="M3574 9777c205,-189 374,-410 439,-718l1370 524c480,-549 1327,-585 1898,0l1423 -528c43,261 182,497 391,715l-1522 586c8,57 9,112 0,165l-2482 0c-12,-59 -11,-118 -3,-177l-1514 -567z"/>
      <path class="fil4" d="M9408 9918l0 603 713 0 -18 -552c-229,38 -459,37 -695,-51z"/>
      <path class="fil4" d="M2578 9927l-9 594 686 0 5 -600c-231,79 -458,74 -682,6z"/>
      <path :fill="color" d="M4049 3292c-79,966 158,1890 1075,2433 779,462 1695,473 2530,0 836,-473 1200,-1297 1130,-2433 -1495,658 -3068,705 -4735,0z"/>
      <path :fill="color" d="M4012 3035c1622,710 3337,802 4817,0l-1 -859c558,-327 724,-790 152,-1185 -1242,-760 -4022,-761 -5194,-4 -524,334 -507,838 231,1193l-5 855z"/>
      <polygon class="fil4" points="3976,2180 8869,2176 8870,3035 3976,3035 "/>
      <circle class="fil4" cx="6402" cy="2011" r="887"/>
    </g>
    </g>
  </g>
  </svg>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    color: {
      type: String,
      default: 'black'
    }
  }
});
</script>

<style type="text/css" scoped>
  .fil3 {fill:#FEFEFE}
  .fil2 {fill:black}
  .fil1 {fill:#00BCD0}
  .fil0 {fill:#00CEB7}
  .fil4 {fill:white}
</style>
